<template>
  <div id="data-list-list-view" class="data-list-container">

       <feather-icon icon="ArrowLeftIcon" class="mb-4" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Hospital')"/>


     <hospital-Tab
                  class="mt-4"
                  :mainTabName="'HospitalDoctors'"
                />

    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
      
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Hospital Specialty") }}</label>

          <v-select
            label="Name"
            v-model="search.SpecialtyID"
            :options="specialities"
            :reduce="ID => ID.ID"
          />
        </div>
    
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Doctor Name") }}</label>
          <vs-input
            v-model="search.DoctorlName"
            class="w-full"
            name="DoctorlName"
          />
        </div>

        <div class="vx-col  w-full">
          <vs-button
            class="my-6 rounded-sm"
            @click="GetHospitalDoctors()"
            color="warning"
            v-scroll-to="'#Scroll'"
            >{{ $t("Search") }}</vs-button
          >
        </div>
      </div>
    </vx-card>
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Hospital Doctors')">
        <vs-button class="mb-5 ml-5" color="success" @click="isAddNew=true">{{
            $t("Add New Hospital Doctor")
          }}</vs-button>
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="HospitalDoctors"
        >
          <template slot="thead">
            <vs-th> {{ $t("Doctor") }}</vs-th>
            <vs-th> {{ $t("Doctor Image") }}</vs-th>
            <vs-th> {{ $t("Specialty Name") }}</vs-th>
            <!-- <vs-th> {{ $t("Position Name") }}</vs-th> -->
            <!-- <vs-th> {{ $t("Activation") }}</vs-th> -->
            <vs-th> {{ $t("Action") }}</vs-th>
       
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
             
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.Doctor.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                 
                  <imageLazy
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + tr.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
                  </p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.HospitalSpecialty.Specialty.Name }}
                  </p>
                </vs-td>
                <!-- <vs-td>
                  <p v-if="tr.Position" class="product-name font-medium truncate">
                    {{ tr.Position.Name }}
                  </p>
                </vs-td> -->
              <!-- <vs-td>
                <vs-checkbox @change="changeStatus(tr)" v-model="tr.IsActive"></vs-checkbox>
                </vs-td> -->
                  <vs-td class="whitespace-no-wrap">
                  <vs-button
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" :to="{ name: 'Hospital' }">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div>
     <vs-popup  :active.sync="isAddNew">
              <vs-card>
             <label class="text-sm opacity-75">{{ $t("Hospital Specialty") }}</label>

          <v-select
            label="SpecialtyName"
            v-model="hospitalDoctorModel.HospitalSpecialty"
            :options="HospitalSpecialities"
            :reduce="ID => ID"
            @input="searchDoctor(hospitalDoctorModel.HospitalSpecialty)"
          />
             <label class="text-sm opacity-75 mt-5">{{ $t("Doctor") }}</label>

           <v-select
           v-if="hospitalDoctorModel.HospitalSpecialty"
            label="Name"
            v-model="hospitalDoctorModel.DoctorID"
            :options="doctors"
            :reduce="ID => ID.ID"
          />
                 <!-- <vs-checkbox class="mt-5 " v-model="hospitalDoctorModel.IsActive">{{ $t("Is Active")}} </vs-checkbox> -->

      <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-center">
          <vs-button :disabled="!(hospitalDoctorModel.DoctorID)" type="border" color="success" @click="AddDoctor">{{
            $t("Add")
          }}</vs-button>
        </div>
      </div>
    </div>
      
                </vs-card>         
       </vs-popup>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import hospitalTab from "@/views/tabs/hospitalTab.vue";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    hospitalTab,
    imageLazy

    // vSelect,
  },

  computed: {
    HospitalDoctors() {
      return this.$store.state.HospitalList.HospitalDoctors;
    },
       specialities() {
      return this.$store.state.HospitalList.specialities;
    },
     HospitalSpecialities() {
      return this.$store.state.HospitalList.HospitalSpecialities;
    },
    doctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
  },
  data() {
    return {
    search: {},
    isAddNew:false,
    hospitalDoctorModel:{},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
         
        years: []
      },
     
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
 
      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile"
    };
  },
  methods: {
     AddDoctor(){
      debugger
     
         var model = {};
       model.IsActive = this.hospitalDoctorModel.IsActive;
       model.HospitalSpecialtyID = this.hospitalDoctorModel.HospitalSpecialty.ID;
       model.DoctorID = this.hospitalDoctorModel.DoctorID;

 if(this.HospitalDoctors.findIndex(element => element.HospitalSpecialtyID ==  model.HospitalSpecialtyID 
      && element.DoctorID == model.DoctorID) >=0){
           this.$vs.notify({
        color: "warning",
        title: "Can't Add",
        text: this.$t("can't add this doctor with this speciality , because you were added it before"),
      });
      this.hospitalDoctorModel = {};
          this.isAddNew = false;
          return 0;
      }

        this.$vs.loading();
      this.$store.dispatch("HospitalList/AddHospitalSpecialtyDoctor",model).then(()=>{
          this.$vs.loading.close();
          window.showSuccess();
          this.hospitalDoctorModel = {};
          this.isAddNew = false;
           var object={};
        object.HospitalID=this.$route.params.ID;
      
      this.$store.dispatch("HospitalList/GetHospitalDoctors", object);
      });
    },
     searchDoctor() {
      debugger;
      var doctorModel={};
      doctorModel.SpecialtyID = this.hospitalDoctorModel.HospitalSpecialty.Specialty.ID;
      doctorModel.HospitalSpecialityID = this.hospitalDoctorModel.HospitalSpecialty.ID;
      this.$store.dispatch("DoctorList/SearchDoctors", doctorModel);
    },
   changeStatus(item){
          this.$vs.loading();

     this.$store
        .dispatch("HospitalList/UpdateHospitalSpecialtyDoctor",item)
        .then(() => {
          window.showSuccess();
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
  },
   openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },
  acceptAlert() {
     this.$store.dispatch("HospitalList/DeleteHospitalSpecialtyDoctor", this.rowDataForDelete).then(()=>{
        this.GetHospitalDoctors(this.$route.params.ID);
      window.showDeleteSuccess();

     });
    },
    GetHospitalDoctors(ID) {
      var ID=ID;
     if(ID == undefined)
       {
         ID = this.$route.params.ID;
       }
      this.search.HospitalID = ID;
    
      this.$vs.loading();

      this.$store
        .dispatch("HospitalList/GetHospitalDoctors",this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
     if (!moduleDoctor.isRegistered) {
      debugger;
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
   // this.GetSurgery();
 
    const ID = this.$route.params.ID;
    if (ID != undefined) {
   
      this.GetHospitalDoctors(ID);
      var object={};
        object.HospitalID=ID;
      
      this.$store.dispatch("HospitalList/GetAllSpecialties", object);
    }
      
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
